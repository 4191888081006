(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/currency-counter/assets/javascripts/currency-counter.js') >= 0) return;  svs.modules.push('/components/lb-ui/currency-counter/assets/javascripts/currency-counter.js');
"use strict";


const {
  useEffect,
  useRef,
  useState
} = React;
const {
  Currency: currency,
  CurrencyFromJupiterString: currencyFromJupiterString
} = svs.utils.format;
const {
  gsapCounterEffect
} = svs.components.lbUi.currencyCounter.gsapCustomEffects;
gsap.registerEffect({
  name: 'customCounter',
  extendTimeline: true,
  effect: gsapCounterEffect
});

const CurrencyCounter = _ref => {
  let {
    amount = 0,
    className,
    uniqueId
  } = _ref;
  const previousAmount = useRef(amount);
  const prevUniqueId = useRef(uniqueId);
  const counterRef = useRef();
  const [isInitialRender, setIsInitialRender] = useState(true);
  const classNames = ['currency-counter'];
  className && classNames.push(className);
  useEffect(() => {
    if (uniqueId !== prevUniqueId.current) {
      previousAmount.current = amount;
      prevUniqueId.current = uniqueId;
      setIsInitialRender(true);
    }
  }, [amount, uniqueId]);
  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    if (previousAmount.current !== amount) {
      const timeline = gsap.timeline();
      const currencyValue = currencyFromJupiterString(amount);
      timeline.customCounter(counterRef.current, {
        end: currencyValue,
        duration: 2
      });
      previousAmount.current = amount;
      return () => {
        timeline.kill();
      };
    }
  }, [amount, isInitialRender]);
  return React.createElement("span", {
    className: classNames.join(' '),
    ref: counterRef
  }, currency(previousAmount.current));
};
setGlobal('svs.components.lbUi.currencyCounter', {
  CurrencyCounter
});

 })(window);